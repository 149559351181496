<template style="display: inline; overflow: hidden">
  <div>
    <div :style="getElementStyle" class="dynamic-font" v-if="isFromDocument">
      <p style="display: inline; overflow: hidden; word-wrap: keep-all">
        {{ processedContent }}
      </p>
    </div>
    <div
      :style="getElementStyle"
      class="dynamic-font"
      v-else-if="!isFromDocument && data.properties.filed_content != 'Hide'"
    >
      <p style="display: inline; overflow: hidden; word-wrap: keep-all">
        {{ processedContent }}
      </p>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
export default {
  name: "templates-formComponentsExecute-SingleLineContentExecute",
  mixins: [userPermissionsHelper],
  props: ["data", "isFromDocument", "colorFields", "label", "form"],
  data() {
    return {
      inputTextAlignmentValue: "left",
    };
  },
  computed: {
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";

        // Add text-align property based on inputTextAlignmentValue
        borderStyle += ";text-align:" + this.inputTextAlignmentValue + ";";
      }
      return borderStyle;
    },

    processedContent() {
      // Replace @key with the value from this.form
      const content = this.label || this.data.content || this.data.value || "";
      return content.replace(/@(\w+)/g, (match, key) => {
        return this.form[key] !== undefined ? this.form[key] : match;
      });
    },
  },
  mounted() {},
  watch: {
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.inputTextAlignmentValue = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
    form: {
      handler() {},
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 450px) {
  .dynamic-font {
    font-size: 14px !important;
  }
}
</style>
